import React from 'react';
import PropTypes from 'prop-types';
import { Spacing, Text, LinkButton, mediaQueries } from '@reservamos/elements';
import { css, useTheme } from '@emotion/react';
import LayoutContainer from '../atoms/LayoutContainer';
import rollBitsLink from '../../constants/brandLinks';

const Hero = ({
  imageAlt,
  imageSrc,
  title,
  heroBackground,
  isVertical,
  isVerticalInMobile,
  hasButton,
  children,
  buttonText,
  fromWidget,
  secondaryText,
  withoutImageMobile,
}) => {
  const { colors } = useTheme();
  const background = css`
    background-color: ${colors.white};
    background-image: url(${Boolean(heroBackground) && heroBackground});
    background-size: contain;
    padding: 15px 10px;
    width: 100%;
  `;

  const horizontalStyles = css`
    max-height: ${isVerticalInMobile ? '500px' : '120px'};
    max-width: 300px;
    ${mediaQueries.small} {
      max-height: ${isVerticalInMobile ? '300px' : '100px'};
    }
    ${mediaQueries.xsmall} {
      max-width: ${isVerticalInMobile ? '200px' : '100px'};
      margin-top: 0px;
    }
  `;

  const heroImage = css`
    max-height: 200px;
    object-fit: contain;
    ${!isVertical && horizontalStyles}
    width: 100%;
    ${mediaQueries.small} {
      ${withoutImageMobile && 'display: none;'}
    }
  `;

  const textContainer = css`
    max-width: 70%;
    ${mediaQueries.small} {
      max-width: inherit;
    }
  `;

  const linkToSearchMore = fromWidget ? rollBitsLink : '/';

  return (
    <div css={background}>
      <LayoutContainer>
        {isVertical ? (
          <Spacing vertical size="S">
            <Text size="XXL" weight="bold" color="primaryStrong">
              {title}
            </Text>
          </Spacing>
        ) : (
          <Spacing
            isResponsive={isVerticalInMobile}
            alignItems="center"
            flexGrow
            size="M"
            responsiveSize="S"
            vertical
          >
            <div css={textContainer}>
              <Text
                size="XXL"
                mobileSize="XL"
                weight="bold"
                color="primaryStrong"
              >
                {title}
              </Text>
            </div>
            {hasButton && (
              <div>
                <LinkButton
                  isRounded
                  variant="accent"
                  text={buttonText}
                  padding="M"
                  fontSize="S"
                  href={linkToSearchMore}
                />
              </div>
            )}
            {Boolean(secondaryText) && secondaryText}
          </Spacing>
        )}
        <img src={imageSrc} css={heroImage} alt={imageAlt} />
        {children}
      </LayoutContainer>
    </div>
  );
};

Hero.propTypes = {
  /** Hero's title */
  title: PropTypes.string,
  /** Logo's alt */
  imageAlt: PropTypes.string,
  /** Logo's src */
  imageSrc: PropTypes.string,
  /** Hero backgorund image */
  heroBackground: PropTypes.string,
  /** Hero layout direction */
  isVertical: PropTypes.bool,
  isVerticalInMobile: PropTypes.bool,
  /** Indicates if hero has button */
  hasButton: PropTypes.bool,
  children: PropTypes.node,
  /** Button's text */
  buttonText: PropTypes.string,
  fromWidget: PropTypes.bool,
  /** Adds secondary Text */
  secondaryText: PropTypes.node,
  /** Removes Hero image for mobile only */
  withoutImageMobile: PropTypes.bool,
};

Hero.defaultProps = {
  imageAlt: null,
  imageSrc: null,
  title: '',
  heroBackground: '',
  isVerticalInMobile: false,
  isVertical: false,
  hasButton: false,
  children: null,
  buttonText: '',
  fromWidget: false,
  secondaryText: null,
  withoutImageMobile: false,
};

export default Hero;
